<template>
    <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pest_management.insect') + ' ' + $t('globalTrans.details')}}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loader">
                <b-row>
                    <b-col md="12" class="table-responsive">
                        <b-table-simple striped bordered small>
                            <b-tr>
                                <b-th>{{$t('globalTrans.organization')}} </b-th>
                                <b-td :colspan="3">{{ organization_name }} </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('VirtualMuseum.configuration.crop_type')}}</b-th>
                                <b-td>{{ crop_type }}</b-td>
                                <b-th>{{ $t('pest_management.crop')}}</b-th>
                                <b-td>{{ crop_name }}</b-td>
                            </b-tr>
                             <b-tr>
                                <b-th>{{ $t('pest_management.insect_name_en')}}</b-th>
                                <b-td>{{ insect.name }}</b-td>
                                <b-th>{{ $t('pest_management.insect_name_bn')}}</b-th>
                                <b-td>{{ insect.name_bn }}</b-td>
                            </b-tr>
                            <!-- <b-tr>
                                <b-th style="vertical-align: middle;">{{ $t('pest_management.description_en')}}</b-th>
                                <b-td style="padding: 10px;">{{ insect.description }}</b-td>
                                <b-th style="vertical-align: middle;">{{ $t('pest_management.description_bn')}}</b-th>
                                <b-td style="padding: 10px;">{{ insect.description_bn }}</b-td>
                            </b-tr> -->
                        </b-table-simple>
                        <!-- ==========Start====== -->
                        <!-- Basic Desc start -->
                        <div v-if="insect.basic_desc || insect.basic_desc_photo" style="background-color: #005B5B; padding: 5px">
                            <h4 class="text-white text-center"> {{$t('pest_management.basic_description')}}</h4>
                        </div>
                        <b-table-simple striped bordered small hover v-if="insect.basic_desc || insect.basic_desc_photo">
                            <b-tbody>
                                <b-tr>
                                    <b-th style="width:35%;">{{$t('pest_management.description_en')}}</b-th>
                                    <b-th style="width:35%;">{{$t('pest_management.description_bn')}}</b-th>
                                    <b-th style="width:30%;">{{$t('pest_management.image')}}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-td style="width:35%;">
                                        {{ insect.basic_desc }}
                                    </b-td>
                                    <b-td style="width:35%;">
                                        {{ insect.basic_desc_bn }}
                                    </b-td>
                                    <b-td class="width30-align-middle">
                                        <b-img v-if="basic_desc_attachment" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="basic_desc_attachment" />
                                        <br/>
                                        {{ insect.basic_desc_photo_src }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <!-- Basic Desc end -->
                        <!-- ==========End====== -->
                        <!-- Life cycle start -->
                        <div v-if="insect.life_cycle_desc || insect.life_cycle_photo" style="background-color: #005B5B; padding: 5px">
                            <h4 class="text-white text-center"> {{$t('pest_management.life_cycle')}}</h4>
                        </div>
                        <b-table-simple striped bordered small hover v-if="insect.life_cycle_desc || insect.life_cycle_photo">
                            <b-tbody>
                                <b-tr>
                                    <b-th style="width:35%;">{{$t('pest_management.description_en')}}</b-th>
                                    <b-th style="width:35%;">{{$t('pest_management.description_bn')}}</b-th>
                                    <b-th style="width:30%;">{{$t('pest_management.image')}}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-td style="width:35%;">
                                        {{ insect.life_cycle_desc }}
                                    </b-td>
                                    <b-td style="width:35%;">
                                        {{ insect.life_cycle_desc_bn }}
                                    </b-td>
                                    <b-td class="width30-align-middle">
                                        <b-img v-if="life_cycle_attachment" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="life_cycle_attachment" />
                                        <br/>
                                        {{ insect.life_cycle_photo_src }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <!-- Life cycle end -->

                        <!-- Destructive stage start -->
                            <div style="background-color: #005B5B; padding: 5px" v-if="insect.destructive_desc || insect.destructive_photo">
                                <h4 class="text-white text-center"> {{$t('pest_management.destructive_stage')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover v-if="insect.destructive_desc || insect.destructive_photo">
                                <b-tbody>
                                    <b-tr>
                                    <b-th style="width:35%;">{{$t('pest_management.description_en')}}</b-th>
                                    <b-th style="width:35%;">{{$t('pest_management.description_bn')}}</b-th>
                                    <b-th style="width:30%;">{{$t('pest_management.image')}}</b-th>
                                </b-tr>
                                    <b-tr>
                                        <b-td style="width:35%;">
                                           {{ insect.destructive_desc }}
                                        </b-td>
                                        <b-td style="width:35%;">
                                           {{ insect.destructive_desc_bn }}
                                        </b-td>
                                        <b-td class="width30-align-middle">
                                            <b-img v-if="destructive_attachment" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="destructive_attachment" />
                                            <br/>
                                        {{ insect.destructive_photo_src }}
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Destructive stage end -->

                            <!-- Attacking Parts of Plant start -->
                            <div style="background-color: #005B5B; padding: 5px" v-if="insect.attacking_parts_desc || insect.attacking_parts_photo">
                                <h4 class="text-white text-center"> {{$t('pest_management.attacking_parts')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover v-if="insect.attacking_parts_desc|| insect.attacking_parts_photo">
                                <b-tbody>
                                    <b-tr>
                                    <b-th style="width:35%;">{{$t('pest_management.description_en')}}</b-th>
                                    <b-th style="width:35%;">{{$t('pest_management.description_bn')}}</b-th>
                                    <b-th style="width:30%;">{{$t('pest_management.image')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:35%;">
                                           {{ insect.attacking_parts_desc }}
                                        </b-td>
                                        <b-td style="width:35%;">
                                           {{ insect.attacking_parts_desc_bn }}
                                        </b-td>
                                        <b-td class="width30-align-middle">
                                            <b-img v-if="attacking_parts_attachment" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="attacking_parts_attachment" />
                                            <br/>
                                            {{ insect.attacking_parts_photo_src }}
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Attacking Parts of Plant end -->
                            <!-- Symptom start -->
                            <div style="background-color: #005B5B; padding: 5px" v-if="insect.symptom_desc || insect.symptom_photo">
                                <h4 class="text-white text-center"> {{$t('pest_management.symptom')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover v-if="insect.symptom_desc || insect.symptom_photo">
                                <b-tbody>
                                    <b-tr>
                                    <b-th style="width:35%;">{{$t('pest_management.description_en')}}</b-th>
                                    <b-th style="width:35%;">{{$t('pest_management.description_bn')}}</b-th>
                                    <b-th style="width:30%;">{{$t('pest_management.image')}}</b-th>
                                </b-tr>
                                    <b-tr>
                                        <b-td style="width:35%;">
                                           {{ insect.symptom_desc }}
                                        </b-td>
                                        <b-td style="width:35%;">
                                           {{ insect.symptom_desc_bn }}
                                        </b-td>
                                        <b-td class="width30-align-middle">
                                            <b-img v-if="symptom_attachment" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="symptom_attachment" />
                                            <br/>
                                            {{ insect.symptom_photo_src }}
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Symptom end -->
                            <!-- Effect on plant start -->
                            <div style="background-color: #005B5B; padding: 5px" v-if="insect.effect_plant_desc || insect.effect_plant_photo">
                                <h4 class="text-white text-center"> {{$t('pest_management.effect_on_plant')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover v-if="insect.effect_plant_desc || insect.effect_plant_photo">
                                <b-tbody>
                                    <b-tr>
                                    <b-th style="width:35%;">{{$t('pest_management.description_en')}}</b-th>
                                    <b-th style="width:35%;">{{$t('pest_management.description_bn')}}</b-th>
                                    <b-th style="width:30%;">{{$t('pest_management.image')}}</b-th>
                                </b-tr>
                                    <b-tr>
                                        <b-td style="width:35%;">
                                           {{ insect.effect_plant_desc }}
                                        </b-td>
                                        <b-td style="width:35%;">
                                           {{ insect.effect_plant_desc_bn }}
                                        </b-td>
                                        <b-td class="width30-align-middle">
                                            <b-img v-if="effect_plant_attachment" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="effect_plant_attachment" />
                                            <br/>
                                            {{ insect.effect_plant_photo_src }}
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Effect on plant end -->

                            <!-- Management start -->
                            <div  style="background-color: #005B5B; padding: 5px" v-if="insect.management_desc || insect.management_photo">
                                <h4 class="text-white text-center"> {{$t('pest_management.management')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover v-if="insect.management_desc || insect.management_photo">
                                <b-tbody>
                                    <b-tr>
                                    <b-th style="width:35%;">{{$t('pest_management.description_en')}}</b-th>
                                    <b-th style="width:35%;">{{$t('pest_management.description_bn')}}</b-th>
                                    <b-th style="width:30%;">{{$t('pest_management.image')}}</b-th>
                                </b-tr>
                                    <b-tr>
                                        <b-td style="width:35%;">
                                            {{ insect.management_desc }}
                                        </b-td>
                                        <b-td style="width:35%;">
                                            {{ insect.management_desc_bn }}
                                        </b-td>
                                        <b-td class="width30-align-middle">
                                            <b-img v-if="management_attachment" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="management_attachment" />
                                            <br/>
                                        {{ insect.management_photo_src }}
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>

                             <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button variant="primary" class="mr-2">
                                        <router-link :class="'text-light'" :to="{ path: '/virtual-museum-service/pest-management/add-insect', query: { id: $route.query.id }}" size="sm">{{ $t('globalTrans.edit') }}
                                        </router-link>
                                    </b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1">
                                        <router-link :class="'text-light'" :to="{ path: '/virtual-museum-service/pest-management/manage-insect'}" size="sm">{{ $t('globalTrans.cancel') }}
                                        </router-link>
                                    </b-button>
                                </div>
                            </div>
                    </b-col>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    </b-container>
</template>

<script>
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { insectManageDetailsApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      insect: {},
      organization_name: '',
      crop_type: '',
      crop_name: '',
      loader: false,
      url: '',
      basic_desc_attachment: '',
      life_cycle_attachment: '',
      destructive_attachment: '',
      attacking_parts_attachment: '',
      effect_plant_attachment: '',
      symptom_attachment: '',
      management_attachment: ''
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loader = true
      const result = await RestApi.getData(virtualMuseumServiceBaseUrl, insectManageDetailsApi + '/' + this.$route.query.id)
      if (result.success) {
          this.insect = result.data
          const org = this.$store.state.commonObj.organizationProfileList.find(org => org.value === this.insect.org_id)
          const cropType = this.$store.state.VirtualMuseum.commonObj.cropTypeList.find(type => type.value === this.insect.crop_type_id)
          const crop = this.$store.state.VirtualMuseum.commonObj.cropList.find(crop => crop.value === this.insect.crop_id)
          this.organization_name = this.$i18n.locale === 'bn' ? org.text_bn : org.text_en
          this.crop_type = this.$i18n.locale === 'bn' ? cropType.text_bn : cropType.text_en
          this.crop_name = this.$i18n.locale === 'bn' ? crop.text_bn : crop.text_en
          this.basic_desc_attachment = virtualMuseumServiceBaseUrl + 'pest-management/' + this.insect.basic_desc_photo
          this.life_cycle_attachment = virtualMuseumServiceBaseUrl + 'pest-management/' + this.insect.life_cycle_photo
          this.destructive_attachment = virtualMuseumServiceBaseUrl + 'pest-management/' + this.insect.destructive_photo
          this.attacking_parts_attachment = virtualMuseumServiceBaseUrl + 'pest-management/' + this.insect.attacking_parts_photo
          this.effect_plant_attachment = virtualMuseumServiceBaseUrl + 'pest-management/' + this.insect.effect_plant_photo
          this.symptom_attachment = virtualMuseumServiceBaseUrl + 'pest-management/' + this.insect.symptom_photo
          this.management_attachment = virtualMuseumServiceBaseUrl + 'pest-management/' + this.insect.management_photo
    }
      this.loader = false
    }
  }
}
</script>
<style scoped>
.padding_left {
 padding-left: 15px
}
.width30-align-middle {
 width:30%;
 text-align: center;
 vertical-align: middle
}
</style>

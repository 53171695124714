// insect routes
export const insectManageListApi = '/manage-pest/insect/list'
export const insectManageStoreApi = '/manage-pest/insect/store'
export const insectManageUpdateApi = '/manage-pest/insect/update'
export const insectManageDetailsApi = '/manage-pest/insect/details'
export const insectManageToggleStatusApi = '/manage-pest/insect/toggle-status'

// disease routes
export const diseaseManageListApi = '/manage-pest/disease/list'
export const diseaseManageStoreApi = '/manage-pest/disease/store'
export const diseaseManageDetailsApi = '/manage-pest/disease/details'
export const diseaseManageUpdateApi = '/manage-pest/disease/update'
export const diseaseManageToggleStatusApi = '/manage-pest/disease/toggle-status'
